<template>
  <div>
    <b-img :src="url" fluid />
  </div>
</template>

<script>
import { BModal, BImg } from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BImg,
  },
  props: {
    url: {
      type: String,
      required: true,
    }
  },
  name: 'ShowImageModal',
}
</script>

<template>
  <div v-if="!loading && detail">
    <div style="overflow-x: hidden">

      <b-row>
        <b-col>
          <div class="d-flex w-100 p-1 gap15px">

            <button type="button" aria-label="Close" class="close text-dark">
              <feather-icon icon="XIcon" size="1x" stroke-width="1" class="text-dark" @click="
              closeView()" />
            </button>

            <div class="w-100 d-flex align-items-center flex-wrap gap15px">
              <div class="fw-bold textHeaderSolicitud">
                Solicitud de retiro #{{ detail.num_register }}
              </div>

              <div :class="`div-${getColorStatus(detail.status)}`">
                {{ detail.status_text }}
              </div>
            </div>

          </div>
        </b-col>
      </b-row>


      <b-row class="mt-3">
        <b-col>
          <div class="d-flex px-3">
            <b-avatar class="mb-1 mr-2" size="6rem" :src="detail.user.avatar_url"
              :initials="detail.user.initial_letters"></b-avatar>
            <div class="d-flex flex-column justify-content-around w-100">
              <div class="">
                <h4 class="mb-0 fw-bold">
                  {{ detail.user.full_name }}
                </h4>
                <strong class="card-text color-gris-text">{{ detail.user.email }}</strong>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <div class="card-text d-flex flex-wrap align-items-center">

                  <div class="position-relative">
                    <feather-icon icon="HexagonIcon" size="2x" stroke-width="2" class="text-blue" />
                    <feather-icon icon="UserIcon" size="1x" stroke-width="3" class="text-blue iconAbsoluteUser" />
                  </div>

                  <strong class="color-gris-text">Cuenta {{ detail.user.account_type }}</strong>
                </div>

                <div class="card-text verified-success" v-if="detail.user.verified">
                  <feather-icon icon="CheckCircleIcon" size="1.3x" stroke-width="2" class="text-white" />
                  <strong class="ml-4px">Verificada</strong>
                </div>
                <div class="card-text verified-error" v-else>
                  <feather-icon icon="XCircleIcon" size="1.3x" stroke-width="2" class="text-white" />
                  <strong class="ml-4px">No Verificada</strong>
                </div>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <div class="d-flex justify-content-between px-3 ">
            <div class="">
              <strong class="fw-bold color-black mr-3px">Nº de operación: </strong>
              {{ detail.reference }}
            </div>

            <div class="">
              {{ dateFormatDetails(detail.created_at) }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="detail" class="mb-5">

        <b-col cols="12 px-3 mt-2">
          <div class="p-1 card-white-radius">
            <div class="d-flex justify-content-between align-items-center">
              <h4>Detalles de la solicitud de retiro</h4>
              <feather-icon :icon="`${expanded_card_1 ? 'ChevronUpIcon' : 'ChevronDownIcon'}`" size="1.5x"
                stroke-width="1" class="text-dark cursor-pointer" @click="
                expanded_card_1 = !expanded_card_1" />
            </div>

            <div style="list-style: none" v-if="expanded_card_1">
              <div class="d-flex justify-content-between align-items-center mt-1 mb-1">
                <strong class="leters_black"> Monto a retirar:</strong>

                <div class="divMount">
                  ${{ numberFormat(detail.amount) }} USD
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Método de pago:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.payment_method.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.payment_method.name }}
                  </span>
                </div>
              </div>

              <div v-if="detail.platform_account.currency.is_crypto" class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Moneda:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.platform_account.currency.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.platform_account.currency.name }}
                  </span>
                </div>
              </div>


              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Medio de pago:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.user_bank_account.bank.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.user_bank_account.bank.name }}
                  </span>
                </div>
              </div>


              <!-- <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Medio de pago:</strong>
                <span class="text-dark font-weight-bold">
                  {{ detail.platform_account.platform_bank.bank.name }}
                </span>
              </div>


              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Cuenta:</strong>
                <span class="text-dark font-weight-bold">
                  {{ detail.platform_account.values.type }}
                </span>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Nº de cuenta:</strong>
                <span class="text-dark font-weight-bold">
                  {{ detail.platform_account.values.number }}
                </span>
              </div> -->

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Monto a transferir:</strong>
                <span class="divMontoTransferir font-weight-bold">
                  {{ numberFormat(detail.received_amount) }} {{ detail.platform_account.currency.code }}
                </span>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Tasa aplicada:</strong>
                <span class="text-dark font-weight-bold">
                  $1 USD = {{ numberFormat(detail.rate) }} {{ detail.platform_account.currency.code }}
                </span>
              </div>


              <!-- <div class="d-flex justify-content-between">
                <strong> Deposito realizado a: </strong>{{ detail.platform_account.values.holder }}
              </div> -->
            </div>
          </div>
        </b-col>

        <b-col cols="12 px-3 mt-2">
          <div class="p-1 card-white-radius">

            <div class="d-flex justify-content-between align-items-center">
              <h4>Datos para el pago</h4>
              <feather-icon :icon="`${expanded_card_2 ? 'ChevronUpIcon' : 'ChevronDownIcon'}`" size="1.5x"
                stroke-width="1" class="text-dark cursor-pointer" @click="
                expanded_card_2 = !expanded_card_2" />
            </div>

            <div style="list-style: none" v-if="expanded_card_2">
              <div v-if="detail.user_bank_account">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Cuenta: </strong>
                  {{ detail.user_bank_account.bank.name }}
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Titular: </strong>
                  {{ detail.user_bank_account.account_name }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Numero de cuenta: </strong>
                  {{ detail.user_bank_account.account_number }}
                </div>

                <div v-if="detail.user_bank_account.identification_type" class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Tipo de identificación: </strong>
                  ({{ detail.user_bank_account.identification_type.code }}) {{ detail.user_bank_account.identification_type.name }}
                </div>

                <div v-if="detail.user_bank_account.identification_number" class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Numero de identificación: </strong>
                  {{ detail.user_bank_account.identification_number }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Tipo de cuenta: </strong>
                  {{ detail.user_bank_account.bank_account_type.name }}
                </div>
              </div>

              <div v-if="detail.personFullname">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Nombre completo: </strong>
                    {{ detail.personFullname }}
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Tipo de Documento: </strong>
                  {{ detail.personDocumentType }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Numero de documento: </strong>
                  {{ detail.personDocumentNumber }}
                </div>
              </div>
            </div>
          </div>
        </b-col>


        <b-col cols="12 px-3 mt-2 mb-5">
          <vue2-dropzone v-if="detail.status === 'pending' || detail.status === 'in_review'" :options="dropzoneOptions" id="customdropzone" :useCustomSlot="true" ref="myVueDropzone"
            v-on:vdropzone-sending="sendingEvent" :max-number-of-files='1' v-on:vdropzone-removed-file="removedEvent">

            <div class="d-flex align-items-center justify-content-center flex-column dz-message">
              <div class="">
                <svg stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  style="fill: rgb(61, 82, 255);height: 70px;width: 70px;color: transparent;" stroke-width="1"
                  fill="none">
                  <path
                    d="M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z">
                  </path>
                  <path
                    d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H13C13.41 1.25 13.75 1.59 13.75 2C13.75 2.41 13.41 2.75 13 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z">
                  </path>
                  <path
                    d="M21.25 5.75H15.75C15.34 5.75 15 5.41 15 5C15 4.59 15.34 4.25 15.75 4.25H21.25C21.66 4.25 22 4.59 22 5C22 5.41 21.66 5.75 21.25 5.75Z">
                  </path>
                  <path
                    d="M18.5 8.5C18.09 8.5 17.75 8.16 17.75 7.75V2.25C17.75 1.84 18.09 1.5 18.5 1.5C18.91 1.5 19.25 1.84 19.25 2.25V7.75C19.25 8.16 18.91 8.5 18.5 8.5Z">
                  </path>
                  <path
                    d="M2.66932 19.6986C2.42932 19.6986 2.18932 19.5786 2.04932 19.3686C1.81932 19.0286 1.90932 18.5586 2.24932 18.3286L7.17932 15.0186C8.25932 14.2986 9.74932 14.3786 10.7293 15.2086L11.0593 15.4986C11.5593 15.9286 12.4093 15.9286 12.8993 15.4986L17.0593 11.9286C18.1293 11.0186 19.7893 11.0186 20.8593 11.9286L22.4893 13.3286C22.7993 13.5986 22.8393 14.0686 22.5693 14.3886C22.2993 14.6986 21.8293 14.7386 21.5093 14.4686L19.8793 13.0686C19.3793 12.6386 18.5293 12.6386 18.0393 13.0686L13.8793 16.6386C12.8193 17.5486 11.1493 17.5486 10.0793 16.6386L9.74932 16.3486C9.28932 15.9586 8.52932 15.9186 8.01932 16.2686L3.09932 19.5786C2.95932 19.6586 2.80932 19.6986 2.66932 19.6986Z">
                  </path>
                </svg>
              </div>
              <div class="fw-bold text-dark">
                Cargar comprobante de pago
              </div>
              <div class="">
                Soporta imagen en formato JPG y PNG
              </div>
            </div>

          </vue2-dropzone>

          <div v-if="detail.status === 'completed'" class="text-center mt-4">
            <b-button @click="showPaymentModal()">Ver comprobante de pago</b-button>
          </div>
        </b-col>

      </b-row>
    </div>

    <template footer v-if="detail.status === 'pending' || detail.status === 'in_review'">
      <div class="d-flex bg-white text-light align-items-center px-2 py-1 divFooter">
        <div cols="12" class="d-flex bg-white align-items-center justify-content-between py-0 w-100 gap15px">
          <b-button variant="danger" class="w-100" @click="approve('rejected')"
            v-if="permissions_requerid.includes('reject-withdrawal')">
            Rechazar retiro
          </b-button>
          <b-button variant="success" class="w-100" @click="approve('completed')"
            v-if="permissions_requerid.includes('approve-withdrawal')">
            Aprobar retiro
          </b-button>
        </div>
      </div>
    </template>
      <b-modal
        size="lg"
        id="show-image-modal"
        v-model="showImageModal"
        title="Imagen"
        ok-only
        ok-title="Cerrar"
      >
        <show-image-modal :url="detail.voucher_of_payment_url"/>
      </b-modal>
  </div>


  <div v-else>
    <div class="text-center text-primary my-5 pt-3">
      <span aria-hidden="true" class="align-middle spinner-grow"></span>
      <strong class="ml-1">Cargando</strong>
    </div>
  </div>

</template>
<script>
import { inputAlert, confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'

import { BSidebar, BButton, BCard, BRow, BCol, BAvatar, BModal } from 'bootstrap-vue'

import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { verifyPermission } from '@/router';

import API from '@/libs/axios'


import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ShowImageModal from '@/components/ShowImageModal'

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  mixins: [numberMixins],

  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    vue2Dropzone,
    ShowImageModal,
    BModal,
  },
  data() {
    return {
      showImageModal: false,
      permissions_requerid: [],
      expanded_card_1: true,
      expanded_card_2: true,
      deleting_all_files: false,
      dropzoneOptions: {
        acceptedFiles: "image/*",
        dictCancelUpload: 'Cancelar Subida',
        dictRemoveFile: 'Quitar Archivo',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, },
        url: `${API.defaults.baseURL}withdrawals/image`,
        addRemoveLinks: true,
        multiple: false,
        uploadMultiple: false,
        maxFiles: 1,
        paramName: "image",
        init: function () {
          this.on("maxfilesexceeded", function (file) {
            // console.log("Para subir una imagen nueva debes eliminar la anterior 1")
          });
        },
        maxfilesexceeded: async function (files) {

          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Para subir una imagen nueva debes eliminar la anterior",
          //     icon: 'CheckCircleIcon',
          //     variant: 'error',
          //   },
          // })

          this.deleting_all_files = true
          // console.log("eliminando")
          // await this.removeAllFiles();
          // console.log("eliminado")

          // console.log("subiendo")
          // await this.addFile(files);
          // console.log("subido")
        },
      },
      isPresentFile: false,
    }
  },
  mounted() {
    // Verify
    let approve_withdrawal = verifyPermission({ namePerm: 'approve-withdrawal' })
    let reject_withdrawal = verifyPermission({ namePerm: 'reject-withdrawal' });
    let permissions_requerid_verify = []
    if (approve_withdrawal) {
      permissions_requerid_verify.push("approve-withdrawal")
    }
    if (reject_withdrawal) {
      permissions_requerid_verify.push("reject-withdrawal")
    }

    this.permissions_requerid = permissions_requerid_verify

  },

  computed: {
    ...mapState({
      loading: (state) => state.withdrawals.loading,
      detail: (state) => state.withdrawals.detail,
    }),
  },
  methods: {
    showPaymentModal() {
      this.showImageModal = true
    },
    async removedEvent(file, error, xhr) {
      if (!this.loading) {
        if (
          !this.deleting_all_files
        ) {
          if (this.id == this.detail.id) {
            await this.$store.dispatch('withdrawals/deleteImage', {
              id: this.id,
            }).then((data) => {
              this.isPresentFile = false
              // if (data != undefined) {
              //   if (data.success) {
              //     this.$swal('Exito', 'La imagen ha sido a eliminada', 'success')
              //   } else {
              //     this.$swal('Error', 'Ha ocurrido un error al eliminar la imagen', 'error')
              //   }
              // }
            })
          }
        }

        // this.deleting_all_files = false

      }
    },
    sendingEvent(file, xhr, formData) {
      formData.append('id', this.detail.id);
      this.isPresentFile = true
    },
    getColorStatus(status) {
      let color = ""

      switch (status) {
        case "created":
          color = "warning"
          break;
        case "pending":
          color = "warning"
          break;
        case "rejected":
          color = "danger"
          break;
        case "in_review":
          color = "warning"
          break;
        case "processing":
          color = "warning"
          break;
        case "completed":
          color = "success"
          break;
        case "canceled":
          color = "danger"
          break;


        default:
          break;
      }

      return color;

    },
    async getDetail() {
      await this.$store.dispatch('withdrawals/getDetail', {
        id: this.id,
      }).then((data) => {
        if (data != null && data.voucher_of_payment_url != undefined) {
          if (data.voucher_of_payment_url != "") {
            var file = { size: 123, name: "Icon", type: "image/png" };
            var url = data.voucher_of_payment_url;
            this.$refs.myVueDropzone.manuallyAddFile(file, url);
          }
        }
      })
    },
    async approve(status) {
      let res = {
        isConfirmed: false,
        value: '',
      }
      if (status !== 'completed') {
        res = await inputAlert(
          'Rechazar retiro',
          'Ingrese el montivo del rechazo'
        )
      } else {
        res = await inputAlert(
          'Aprobar retiro de dinero',
          'Ingrese la referencia del deposito'
        )
      }

      if (!res.isConfirmed) return

      // validate if completed than reference_transfer_money is required
      if ((status === 'completed' && !res.value) || (status === 'completed' && !this.isPresentFile)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La referencia del deposito es requerida al igual que la imagen del deposito',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

    const response = await this.$store.dispatch('withdrawals/approve', {
        id: this.detail.id,
        status: status,
        motive_rejected: status !== 'completed' ? res.value : '',
        reference_transfer_money: status === 'completed' ? res.value : '',
      })

    if (response.success) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title:
            status !== 'completed'
              ? 'El retiro se rechazo correctamente'
              : 'El retiro se aprobo correctamente',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: response.message,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    }

      this.$emit('close')
    },
    closeView() {
      this.$emit('close')
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getDetail()
      }
    },
  },
}
</script>


<style>
#customdropzone {
  background-color: transparent;
  border: dashed 2px #aaa;
  border-radius: 10px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: all .2s linear;
  height: 200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#customdropzone:hover {
  border: dashed 2px #3d52ff;
}


#customdropzone .dz-preview {
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

#customdropzone .dz-preview:hover {
  border-radius: 10px;
}

#customdropzone .dz-preview .dz-image {
  margin-left: 0 !important;
}

#customdropzone .dz-preview .dz-image {
  width: 90px;
  height: 90px;
  margin-left: 0px;
  margin-bottom: 10px;
  object-fit: contain;
}

#customdropzone .dz-preview .dz-image>div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image>img {
  width: 100%;
  width: 90px !important;
  height: 90px !important;
  /* margin-left: 0px !important; */
  /* margin-bottom: 10px; */
  object-fit: contain;
}

#customdropzone .dz-preview .dz-details {
  color: black;
  transition: opacity .2s linear;
  text-align: center;
  border-radius: 10px;
}


#customdropzone .dz-preview .dz-remove {
  margin-left: 0px !important;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

/* ///////////////////////////////// */
.divFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  box-shadow: 0px 5px 25px 0px #7A7A7A4D;
}

.gap15px {
  gap: 15px;
}

.fw-bold {
  font-weight: bold;
}

.card-white-radius {
  background: white;
  border-radius: 10px;
}

.color-black {
  color: black;
}

.leters_black {
  font-weight: bold;
  color: black;
}

.divMount {
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  color: black;
  font-size: 14px;
  background: #efefef;
}

.imgMethod {
  height: 20px;
  margin-right: 4px;
}


.verified-success {
  background: #32e26d;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
}

.verified-error {
  background: #ff4d4d;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
}

.mr-3px {
  margin-right: 3px;
}

.ml-4px {
  margin-left: 4px;
}

.text-underline {
  text-decoration: underline;
}

.divMontoTransferir {
  background: #3d52ff;
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.div-warning {
  background: #fff8ca;
  color: #ff9f43;
  padding: 3px 7px;
  border-radius: 50px;
}

.div-danger {
  background: #ff5f52;
  color: #8e0000;
  padding: 3px 7px;
  border-radius: 50px;
}

.div-success {
  background: #60ad5e;
  color: #005005;
  padding: 3px 7px;
  border-radius: 50px;
}

.color-gris-text {
  color: #b3b1b9 !important;
}

.text-blue {
  color: #6071fc;
}

.iconAbsoluteUser {
  position: absolute;
  left: 7px;
  top: 6px;
}

.textHeaderSolicitud {
  color: #6e6b7b;
  font-size: 16px;
}
</style>